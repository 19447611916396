import React from 'react';
import { bool, string, arrayOf, shape } from 'prop-types';
import uuidv4 from 'uuidv4';
import cx from 'classnames';
import namespace from '../../../tools/Namespace';
import Image from '../Image';

const ResponsiveImage = ({
  className,
  srcSets,
  alt,
  title,
  fallback,
  fluid,
}) => {
  const classes = cx(namespace('picture'), className);
  const sets = srcSets.map(srcSetsMapped => {
    // `type` is not a required attribute so we add it conditionally.
    const type = srcSetsMapped.type ? { type: `${srcSetsMapped.type}` } : null;

    return React.createElement('source', {
      key: uuidv4(),
      srcSet: srcSetsMapped.src,
      media: `all and (min-width: ${srcSetsMapped.size})`,
      ...type,
    });
  });

  return (
    <picture className={classes}>
      {sets}
      <Image
        fluid={fluid}
        src={fallback}
        alt={alt}
        title={title}
        typeof="foaf:Image"
      />
    </picture>
  );
};

ResponsiveImage.defaultProps = {
  className: null,
  title: null,
  fluid: false,
};

ResponsiveImage.propTypes = {
  /**
   * Useful to extend the style applied to components.
   */
  className: string,
  /**
   * Responsive image source set.
   */
  srcSets: arrayOf(
    shape({
      /**
       * Image size attributes.
       */
      size: string.isRequired,
      /**
       * Image src URL.
       */
      src: string.isRequired,
      /**
       * Image MIME-type so browser knows when to support or ignore srcset.
       */
      type: string,
    }),
  ).isRequired,
  /**
   * Image alt text.
   */
  alt: string.isRequired,
  /**
   * Image title text.
   */
  title: string,
  /**
   * Image fallback for non-supporting devices and browsers.
   */
  fallback: string.isRequired,
  /**
   * The image will take up the entire width of the container.
   */
  fluid: bool,
};

export default ResponsiveImage;
