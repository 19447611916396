import React, { Fragment } from 'react';
import cx from 'classnames';
import Container from '../../Layouts/Container';
import Logo from '../Logo';
import styles from './Header.module.scss';
import Heading from '../../Atoms/Heading';

const Header = () => {
  return (
    <Fragment>
      <header className={styles.headerwrapper}>
        <Container>
          <div className={cx('App-header', styles.header)}>
            <div className={styles.logo}>
              <Logo fill="#FFFFFF" />
              <Heading tag="h2" className={cx('h4', styles.tag)}>
                the blog
              </Heading>
            </div>
          </div>
        </Container>
      </header>
    </Fragment>
  );
};

export default Header;
