import React from 'react';
import { bool, node, string } from 'prop-types';
import cx from 'classnames';
import namespace from '../../../tools/Namespace';

const Copy = ({ children, className, inverted, maxLength, ...props }) => {
  const classes = cx(namespace('copy'), className, {
    [namespace('copy--inverted')]: inverted,
    [namespace('copy--max')]: maxLength,
  });
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

Copy.defaultProps = {
  className: null,
  inverted: false,
  maxLength: false,
};

Copy.propTypes = {
  children: node.isRequired,
  className: string,
  /** Adds an inverted copy class for styling. */
  inverted: bool,
  /** Caps copy at a maximum length to retain readability. */
  maxLength: bool,
};

export default Copy;
