/**
 * Library namespace prefix.
 */
const LIB = 'nrg';

/**
 * Library namespace one or many classes.
 *
 * @param {string|Array} values - Value(s) to namespace.
 * @returns {string} - Prefixed value(s) with the namespace.
 */
const namespace = values => {
  if (!values) return null;

  // Handle values as an array.
  if (Array.isArray(values)) {
    return values.map(value => `${LIB}-${value}`).join(' ');
  }

  return `${LIB}-${values}`;
};

export default namespace;
