import React from 'react';
import { string } from 'prop-types';
import uuidv4 from 'uuidv4';
import cx from 'classnames';
import Link from '../../Atoms/Link';
import styles from './SocialNav.module.scss';
import strings from '../../../translations';
import PATHS from '../../../routes';
import Instagram from '../../IconLibrary/Icons/Instagram';
import YouTube from '../../IconLibrary/Icons/YouTube';
import Heart from '../../IconLibrary/Icons/Heart';

const { INSTAGRAM, YOUTUBE } = strings.GLOBAL.NAVIGATION.SOCIAL_NAV;

const Navigation = ({ className, color }) => {
  const links = [
    {
      href: PATHS.ONLINE_SHOP,
      label: 'Shop',
      icon: <Heart color={color} size="large" />,
    },
    {
      href: PATHS.INSTAGRAM_ACCOUNT,
      label: INSTAGRAM,
      icon: <Instagram color={color} size="large" />,
    },
    {
      href: PATHS.YOUTUBE_ACCOUNT,
      label: YOUTUBE,
      icon: <YouTube color={color} size="large" />,
    },
  ];

  return (
    <nav id="navigation" className={cx(className, styles.nav)}>
      {links &&
        links.map(link => (
          <div className={styles.navitem} key={uuidv4()}>
            <Link href={link.href} target="_blank">
              {link.icon}
            </Link>
          </div>
        ))}
    </nav>
  );
};

Navigation.propTypes = {
  className: string,
  color: string,
};

Navigation.defaultProps = {
  className: null,
  color: '#875d6b',
};

export default Navigation;
