import React from 'react';
import { node } from 'prop-types';
import cx from 'classnames';
import styles from './ContentPageLayout.module.scss';
import CopyLayout from '../CopyLayout';

const ContentPageLayout = ({ children }) => (
  <div className={cx('root-content', styles.container)}>
    <CopyLayout childClass={styles.content}>{children}</CopyLayout>
  </div>
);

ContentPageLayout.propTypes = {
  children: node.isRequired,
};

export default ContentPageLayout;
