import React from 'react';
import Container from '../../Layouts/Container';
import FooterMenu from '../../Navigation/FooterMenu';
import SocialNav from '../../Navigation/SocialNav';
import Logo from '../Logo';
import styles from './Footer.module.scss';
import strings from '../../../translations';

const { COPYRIGHT_TEXT } = strings.GLOBAL.FOOTER;

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.layout}>
          <div className={styles.logo}>
            <Logo fill="#FFFFFF" />
          </div>
          <FooterMenu />
          <SocialNav />
        </div>
        <div className={styles.copyright}>
          <span>
            {COPYRIGHT_TEXT.replace('{year}', new Date().getFullYear()).trim()}
          </span>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
