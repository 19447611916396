import React from 'react';
import Icon from '../components/IconLibrary/Icon';

/**
 * Helper function for setting up SVG Icon.
 *
 * @param {string} path
 * @param {string} name
 * @param {Object} props
 */
function createSvgIcon(path, name, props) {
  const svgPath = path.props && path.props.d ? path.props.d : path;
  const svgIcon = () => {
    return <Icon {...props} path={svgPath} />;
  };
  return svgIcon;
}

export default createSvgIcon;
