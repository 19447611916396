import { createBrowserHistory } from 'history';

// Navigation manager, e.g. history.push('/home')
// https://github.com/mjackson/history
export default process.env.BROWSER &&
  createBrowserHistory({
    basename: '', // The base URL of the app
    forceRefresh: false, // Set true to force full page refreshes
    keyLength: 6, // The length of location.key
  });
