import React, { Component } from 'react';
import { string, bool } from 'prop-types';
import cx from 'classnames';
import Heading from '../../Atoms/Heading';
import ButtonLink from '../RouterLink';
import styles from './BannerImage.module.scss';

class BannerImage extends Component {
  static propTypes = {
    classNames: string,
    title: string,
    tagline: string,
    image: string,
    isBanner: bool,
    link: string,
    narrow: bool,
    linkText: string,
  };

  static defaultProps = {
    classNames: null,
    title: '',
    image: 'https://s3-eu-west-1.amazonaws.com/4ad-nrgapp/DSC_0851S-min.JPG',
    isBanner: true,
    tagline: null,
    link: null,
    narrow: false,
    linkText: 'More info',
  };

  render() {
    const {
      classNames,
      title,
      image,
      isBanner,
      tagline,
      link,
      narrow,
      linkText,
    } = this.props;

    return (
      <div className={cx({ [styles.wrapper]: isBanner })}>
        <div
          className={cx(
            styles.contentlayout,
            { [styles.default]: !narrow },
            { [styles.narrow]: narrow },
          )}
        >
          <div className={styles.spacing}>
            {title && (
              <Heading
                tag="h4"
                className={cx(
                  styles.imagetitle,
                  { h2: !narrow },
                  { h4: narrow },
                )}
              >
                {title}
              </Heading>
            )}
            {tagline && (
              <Heading tag="h4" className={cx('h4', styles.imagetagline)}>
                {tagline}
              </Heading>
            )}
            {link && (
              <div>
                <ButtonLink
                  type="Button"
                  tag="link"
                  href={link}
                  variants={['primary', 'outline', 'inverted']}
                >
                  {linkText}
                </ButtonLink>
              </div>
            )}
          </div>
        </div>
        <div
          className={cx(
            styles.imagelayout,
            { [styles.default]: !narrow },
            { [styles.narrow]: narrow },
          )}
        >
          <img
            alt={title}
            className={cx(classNames, styles.image)}
            src={image}
          />
        </div>
      </div>
    );
  }
}

export default BannerImage;
