import React from 'react';
import { oneOf, node, string } from 'prop-types';
import cx from 'classnames';
import namespace from '../../../tools/Namespace';

const Container = ({ children, className, spacing, ...props }) => {
  const classes = cx(namespace('container'), className, {
    [cx(namespace(`container--${spacing}`))]: spacing,
  });

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};
Container.defaultProps = {
  className: null,
  spacing: null,
};

Container.propTypes = {
  children: node.isRequired,
  className: string,
  spacing: oneOf(['feature', 'component', 'element', 'atom']),
};

export default Container;
