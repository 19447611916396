import React from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';
import Container from '../../Layouts/Container';
import styles from './CopyLayout.module.scss';

const CopyLayout = ({ children, className, childClass, ...props }) => (
  <Container className={cx(styles.wrapper, className)} {...props}>
    <div className={styles.layout}>
      <div className={cx(styles.content, childClass)}>{children}</div>
    </div>
  </Container>
);

CopyLayout.defaultProps = {
  className: null,
  childClass: null,
};

CopyLayout.propTypes = {
  children: node.isRequired,
  className: string,
  childClass: string,
};

export default CopyLayout;
