import React, { Fragment } from 'react';
import uuidv4 from 'uuidv4';
import cx from 'classnames';
import Heading from '../Atoms/Heading';
import Card from '../Molecules/Card';
import CardMedia from '../Molecules/CardMedia';
import CardContent from '../Molecules/CardContent';
import strings from '../../translations';
import styles from './BlogList.module.scss';
import Link from '../UI/RouterLink';

const { BLOG } = strings;
const { POSTS } = BLOG;

const BlogList = () => {
  const POSTS_RENDER = POSTS.map(data => {
    return (
      <Link key={uuidv4()} href={`/article/${data.ID}`}>
        <Card className={cx(styles.boxed, styles.layout)}>
          <CardMedia
            src={data.IMAGE}
            alt={data.TITLE}
            title={data.TITLE}
            className={styles.images}
          />
          <CardContent className={styles.padded}>
            <Heading tag="h2" className="h3">
              {data.TITLE}
            </Heading>
            <div>Reading time : {data.READING_TIME}</div>
            <div>{data.DATE}</div>
          </CardContent>
        </Card>
      </Link>
    );
  });
  return (
    <Fragment>
      <div className={styles.bloggrid}>{POSTS_RENDER}</div>
    </Fragment>
  );
};

export default BlogList;
