import React, { Fragment } from 'react';
import uuidv4 from 'uuidv4';
import DocumentTitle from 'react-document-title';
import Heading from '../../Atoms/Heading';
import ContentPageLayout from '../../Elements/ContentPageLayout';
import ContentRowsLayout from '../../Elements/ContentRowsLayout';
import BannerImage from '../../UI/BannerImage';
import strings from '../../../translations';

const { SITENAME } = strings.GLOBAL;
const { TITLE, SECTIONS } = strings.PAGES.PRIVACY_POLICY;

const LIST = SECTIONS.map(data => {
  const { DETAILS } = data;

  const PARAS = DETAILS.map(copy => {
    return <p key={uuidv4()}>{copy.TEXT}</p>;
  });

  return (
    <div key={uuidv4()}>
      <Heading tag="h1" className="h3">
        {data.TITLE}
      </Heading>
      <div>{PARAS}</div>
    </div>
  );
});

const PrivacyPolicy = () => (
  <DocumentTitle title={`${TITLE} | ${SITENAME}`}>
    <Fragment>
      <BannerImage
        image="https://s3-eu-west-1.amazonaws.com/4ad-nrgapp/DSC_0897S-min.JPG"
        title={TITLE}
      />
      <ContentPageLayout>
        <ContentRowsLayout>
          <Heading tag="h1" className="sr-only">
            PrivacyPolicy
          </Heading>
          <div>{LIST}</div>
        </ContentRowsLayout>
      </ContentPageLayout>
    </Fragment>
  </DocumentTitle>
);

export default PrivacyPolicy;
