import React, { Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import Heading from '../../Atoms/Heading';
import ContentPageLayout from '../../Elements/ContentPageLayout';
import ContentRowsLayout from '../../Elements/ContentRowsLayout';
import BannerImage from '../../UI/BannerImage';
import strings from '../../../translations';

const { SITENAME } = strings.GLOBAL;
const { TITLE } = strings.PAGES.NOT_FOUND;

const NotFound = () => (
  <DocumentTitle title={`${TITLE} | ${SITENAME}`}>
    <Fragment>
      <BannerImage
        image="https://s3-eu-west-1.amazonaws.com/4ad-nrgapp/DSC_0858C-min.JPG"
        title={TITLE}
      />
      <ContentPageLayout>
        <ContentRowsLayout>
          <Heading tag="h1" className="sr-only">
            NotFound
          </Heading>
          <div>Page Not Found</div>
        </ContentRowsLayout>
      </ContentPageLayout>
    </Fragment>
  </DocumentTitle>
);

export default NotFound;
