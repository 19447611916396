import React from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';
import namespace from '../../../tools/Namespace';
import Copy from '../../Atoms/Copy';

const CardContent = ({ children, className, ...props }) => {
  const classes = cx(namespace('card__content'), className);

  return (
    <Copy className={classes} {...props}>
      {children}
    </Copy>
  );
};
CardContent.defaultProps = {
  className: null,
};

CardContent.propTypes = {
  /**
   * Child nodes to render inline.
   */
  children: node.isRequired,
  /**
   * Useful to extend the style applied to components.
   */
  className: string,
};

export default CardContent;
