import React, { Fragment } from 'react';
import Heading from '../../Atoms/Heading';
import ContentPageLayout from '../../Elements/ContentPageLayout';
import ContentRowsLayout from '../../Elements/ContentRowsLayout';
import CopyLayout from '../../Elements/CopyLayout';
import BannerImage from '../../UI/BannerImage';
import strings from '../../../translations';
import BlogList from '../../BlogList';

const { TITLE } = strings.PAGES.HOME;

const Homepage = () => (
  <Fragment>
    <BannerImage
      image="/images/blog-banner-1-min.jpg"
      isBanner
      tagline="'Elegance is when the inside is as beautiful as the outside.' [Coco Chanel]"
      narrow
      link="/about"
      linkText="about me"
    />
    <ContentPageLayout>
      <ContentRowsLayout>
        <Heading tag="h1" className="sr-only">
          {TITLE}
        </Heading>
        <CopyLayout>
          <BlogList />
        </CopyLayout>
      </ContentRowsLayout>
    </ContentPageLayout>
  </Fragment>
);

export default Homepage;
