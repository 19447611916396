import React from 'react';
import { oneOf, string } from 'prop-types';
import cx from 'classnames';
import namespace from '../../../tools/Namespace';

const Icon = ({ className, color, path, size, title, viewBox }) => {
  const classes = cx(
    namespace(['icon']),
    { [namespace(`icon--${size}`)]: size },
    className,
  );
  return (
    <svg className={classes} viewBox={viewBox}>
      {title && <title id="title">{title}</title>}
      <path style={{ fill: color }} d={path} />
    </svg>
  );
};

Icon.defaultProps = {
  className: null,
  color: null,
  size: null,
  title: null,
  path: null,
  /** Material Icons artboard size */
  viewBox: '0 0 24 24',
};

Icon.propTypes = {
  /** Additional classes */
  className: string,
  /** Fill color */
  color: string,
  /** Contents of the SVG path */
  path: string,
  /** Size of the icon */
  size: oneOf(['small', 'large', 'xlarge']),
  /** Icon title, useful for accessibility */
  title: string,
  /** Artboard size of the icon */
  viewBox: string,
};

export default Icon;
