import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { string, object, oneOfType } from 'prop-types';

class ScrollToTop extends Component {
  static propTypes = {
    location: oneOfType([string, object]),
    children: oneOfType([string, object]),
  };
  static defaultProps = {
    location: null,
    children: null,
  };
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(ScrollToTop);
