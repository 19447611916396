import React from 'react';
import cx from 'classnames';
import { node, string, oneOfType, oneOf, arrayOf, element } from 'prop-types';
import namespace from '../../../tools/Namespace';

const Type = ({ children, as: Tag, className, size, align }) => {
  const classes = cx(
    { [namespace(`text--${align}`)]: align },
    { [namespace(`text--${size}`)]: size },
    className,
  );

  return <Tag className={classes}>{children}</Tag>;
};

Type.propTypes = {
  // eslint-disable-next-line react/no-typos
  children: oneOfType([string, arrayOf(node), node, arrayOf(element), element])
    .isRequired,
  // eslint-disable-next-line react/no-typos
  as: oneOf([
    'p',
    'span',
    'label',
    'strong',
    'em',
    'b',
    'i',
    'u',
    'del',
    'ins',
    's',
    'sup',
    'sub',
    'small',
    'abbr',
    'kbd',
    'q',
    'cite',
    'dfn',
    'code',
    'samp',
    'var',
    'pre',
    'mark',
    'div',
  ]),
  className: string,
  size: oneOf(['small', 'large']),
  align: oneOf(['left', 'right', 'center', 'justify']),
};

Type.defaultProps = {
  className: null,
  size: null,
  align: 'left',
  as: 'span',
};

export default Type;
