import React from 'react';
import Highlight from '../components/IconLibrary/Icons/Minus';
import Image from '../components/Atoms/Image';

export default {
  GLOBAL: {
    SITENAME: 'NRG barrebody',
    FOOTER: {
      COPYRIGHT_TEXT: '© Copyright NRG barrebody {year}',
    },
    NAVIGATION: {
      MAIN: {
        HOME: 'Home',
        WHAT_WE_DO: 'What We Do',
        ABOUT: 'About',
        LOCATION: 'Locations',
        PRIVACY_POLICY: 'Privacy Policy',
        COOKIE_POLICY: 'Cookie Policy',
        STUDIO_POLICY: 'Studio Policy',
        TESTIMONIALS: 'Testimonials',
        SCHEDULE: 'Schedule',
      },
      SOCIAL_NAV: {
        INSTAGRAM: 'Instagram',
        YOUTUBE: 'Youtube',
      },
    },
  },
  PAGES: {
    HOME: {
      TITLE: 'NRG',
      INTRO: 'Welcome to NRG',
    },
    ABOUT: {
      TITLE: 'About',
      INTRO: 'Who Am I ? What is NRG barrebody?',
      INFO: [
        {
          TEXT:
            'I am Nathalie Errandonea - Mewes, I am 38 years old and the founder of NRG barrebody, a barre community based in North London. Although, I was born in Australia, both my parents are French and I was raised in France.',
        },
        {
          TEXT:
            'I attended the Conservatoire Rosella Hightower from the age of 3 until I turned 19. I had to stop dancing for health reasons and attended a business school, completing a Master in communication in Paris and went on to have a career in marketing and fashion.',
        },
        {
          TEXT:
            'I travelled to the US for a year and then came to London when I was 24 years old and stayed after meeting my husband. We have 2 children, a girl and and boy, who are 7 and 4 respectively.',
        },
        {
          TEXT:
            'After the birth of my second child, fitness and the passion for dancing caught up with me again. I knew I had to make a drastic change of career, work very hard and above all follow my dream. I did my barre certification and started developing my own barre method and community. NRG barrebody was created 3 years ago and is growing strong.',
        },
        {
          TEXT:
            'My main goal, and ultimately what I am trying to achieve with NRG Barrebody, is to help people integrate fitness into their daily life, the same way people shower or brush their teeth. I believe people should workout everyday to reap the benefits inside and out.',
        },
        {
          TEXT:
            'Being a mother, I know the ups and downs of getting back into shape and the daily challenges that raising children entails. Whether it is the lack of time, motivation or another factor, NRG barrebody content will hopefully inspire you to live a more fitness mindful life. I feel very strongly about helping women and especially mothers, which is why my barre workouts are designed to tone up various group muscles, work on posture and alignment, and gain more energy as efficiently as possible, to help boost confidence and a positive attitude daily .',
        },
        {
          TEXT:
            'In every blog post, hopefully, I will give you some short and precise answers on all things barre and fitness.',
        },
        {
          TEXT:
            'I hope you enjoy it and have fun reading me. Please don’t hesitate to send me your questions and I will do my best to answer them in my future blog posts. Email me at nathalie@n-rg.co.uk.',
        },
        {
          TEXT: 'Nat xo',
        },
      ],
    },
    COOKIE_POLICY: {
      TITLE: 'Cookie Policy',
      INTRO:
        'This is the Cookie Policy for NRG, accessible from http://www.n-rg.co.uk/.',
      SECTIONS: [
        {
          TITLE: 'What Are Cookies',
          DETAILS: [
            {
              TEXT:
                "As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.",
            },
            {
              TEXT:
                'For more general information on cookies see the Wikipedia article on HTTP Cookies.',
            },
          ],
        },
        {
          TITLE: 'How We Use Cookies',
          DETAILS: [
            {
              TEXT:
                'We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.',
            },
          ],
        },
        {
          TITLE: 'Disabling Cookies',
          DETAILS: [
            {
              TEXT:
                'You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.',
            },
          ],
        },
        {
          TITLE: 'The Cookies We Set',
          DETAILS: [
            {
              TEXT: 'Site preferences cookies',
            },
            {
              TEXT:
                '* In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.',
            },
          ],
        },
        {
          TITLE: 'Third Party Cookies',
          DETAILS: [
            {
              TEXT:
                'In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.',
            },
            {
              TEXT:
                'This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content. For more information on Google Analytics cookies, see the official Google Analytics page.',
            },
          ],
        },
        {
          TITLE: 'More Information',
          DETAILS: [
            {
              TEXT:
                "Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site. This Cookies Policy was created with the help of the CookiePolicyGenerator.com",
            },
            {
              TEXT:
                'However if you are still looking for more information then you can contact us through one of our preferred contact methods: Email: admin@n-rg.co.uk',
            },
          ],
        },
      ],
    },
    PRIVACY_POLICY: {
      TITLE: 'Privacy Policy',
      INTRO:
        'NRG takes your privacy seriously. Please read the following to learn more about our privacy policy for adults as well as children under 12. NRG barrebody is a  fitness studio which offers fitness classes in 3 studios in North London but also Online. Through their website, www.n-rg.co.uk and through mobile applications, NRG may collect information for sales and marketing purposes from consumers. NRG values its customers and their concerns about privacy. To this end, NRG engages in consistent information practices and uses its best efforts to make clear disclosures regarding those practices. This privacy policy is a part of that effort. In general, NRG does not share your personally identifying information with third parties unless you permit it.',
      SECTIONS: [
        {
          TITLE: 'General',
          DETAILS: [
            {
              TEXT:
                'This Privacy Policy applies to the collection, use, storage, and disclosure of customer information by NRG. By using our website, you consent to the collection and use of information as outlined in this Privacy Policy. We will clearly disclose any exceptions to the Privacy Policy prior to collecting any information from our users.',
            },
            {
              TEXT:
                'NRG may decide to modify or amend this Privacy Policy from time to time. When we do, we will post any changes or new language here.',
            },
            {
              TEXT:
                'If you are under 18, please be sure to read this policy with your parents so that you understand how your personal information will be used by NRG. If you are under 13, please get your parent’s or legal guardian’s permission before giving us your e-mail address or any personal information.',
            },
            {
              TEXT:
                'As used in this privacy policy, the word “parent” includes both parents and legal guardians.',
            },
            {
              TEXT: 'Collection and Use of Information from Users 13 and Older',
            },
            {
              TEXT:
                'NRG may collect contact information including your name, address, e-mail address, and other personally identifying information when you place an order or request information through our website. Contact information may be used by NRG or shared with third parties for the purposes of processing your transaction, including fraud prevention and credit card authorization. Additionally, NRG may use contact information to notify you about new products, promotions, and special offers that will benefit you, as well as general news about NRG that may be of interest to you. If you are not interested in receiving this information, please email us at nathalie@n-rg.co.uk and we will remove your name from our mailing lists.',
            },
          ],
        },
        {
          TITLE: 'Collection and Use of Information from Users Younger than 13',
          DETAILS: [
            {
              TEXT:
                'To the extent NRG collects information from users who are younger than 13, NRG complies with the Children’s Online Privacy Protection Act (“COPPA”). COPPA requires that NRG inform parents about our collection, use, and disclosure of personal information obtained from children younger than 13, and that we obtain the consent of parents before allowing those children to use certain features of our website.',
            },
            {
              TEXT:
                "We may collect a child’s personally identifiable information, including names, birthdates, and e-mail addresses, for use in promotions, surveys, requests for suggestions, requests for additional information. For children under 13, we will initially collect only the child’s name and e-mail address as well as the parent’s e-mail address. Before using a child’s personal information for these purposes, we will send the child’s parent an e-mail that informs the parent that we wish to collect and use the child’s personal information and request the parent’s consent to do so. We will also provide the parent a link to access this Privacy Policy, which describes our practices and policies with regard to information collection practices and use of information as required by COPPA. If the parent does not provide consent within two (2) weeks of our request, any information collected online by NRG will be deleted from NRG's records.",
            },
            {
              TEXT:
                'We may also collect a child’s online contact information in connection with the child’s request to receive NRG’s newsletter or other e-mail communications from NRG. In this case, we will also collect the parent’s e-mail address and, before the child receives e-mail communication from us, we will notify the parent and allow them to opt out of further use of the information collected from the child.',
            },
            {
              TEXT:
                'If NRG collects information from a child in order to participate in an activity on the website, NRG will not condition the child’s participation on the disclosure of more information than is reasonably necessary to participate in that activity.',
            },
            {
              TEXT:
                'Please note that parents can revoke their consent to NRG ’s collection or use of their child’s personal information at any time by emailing nathalie@n-rg.co.uk with their request. Parents may also unsubscribe from our newsletters or other e-mail communications by clicking on the unsubscribe link included in those e-mails.',
            },
            {
              TEXT:
                'Parents have the right to review their child’s personal information in NRG ’s possession upon request; such requests may be submitted to nathalie@n-rg.co.uk .',
            },
            {
              TEXT:
                'NRG does not share a child’s personally identifiable information with third parties except in the rare case that the disclosure is reasonable necessary to comply with law, or to protect the security of a child or our web site.',
            },
          ],
        },
        {
          TITLE: 'Data Storage',
          DETAILS: [
            {
              TEXT:
                'To prevent unauthorized access, maintain data accuracy, and ensure the appropriate use of information, NRG has physical, electronic, and managerial procedures to safeguard and secure the information it collects online.',
            },
          ],
        },
        {
          TITLE: 'More Information',
          DETAILS: [
            {
              TEXT:
                'If you have any questions about anything in this Privacy Policy, or about our collection of personally identifiable information, or information generally, please contact NRG by email: nathalie@n-rg.co.uk.',
            },
          ],
        },
      ],
    },
    NOT_FOUND: {
      TITLE: 'Page Not Found',
      INTRO: 'Oops. This is embarrassing... ',
    },
  },
  UI: {},
  BLOG: {
    TITLE: 'Blog posts',
    POSTS: [
      {
        ID: 5,
        TITLE: '5 barre exercises to tone up the waist',
        READING_TIME: '7 mins',
        DATE: '07/07/2019',
        IMAGE: '/images/bp6-3.jpg',
        PARAS: [
          {
            TEXT:
              'Do you wish to get a toner, more define and ultimately slimmer waistline with the help of barre related fitness exercises?',
          },
          {
            TEXT: 'If yes, then keep reading…',
          },
          {
            TEXT:
              'Now, just to be clear, in order to achieve any goals  you have to do the work. The work in this case is going to be slowing down on sugar, cakes, bread, you know what I am talking about… otherwise it will not work, period.',
          },
          {
            TEXT:
              'According to me, a healthy person has a good combination of fitness, good nutrition and mental balance and happiness, it’s silly to do all the work in the studio and eat a diet packed full of sugary foods and carbs and complain you are not healthy, looking healthy or that you are feeling down and depressed! To all the mums out there, don’t snack on your kids food unless you run around as much as the kids do from sun up to sun down ;). Noted?',
          },
          {
            TEXT:
              'My top 5 barre exercises to tone up the waist and tummy area:',
          },
          { TEXT: <Highlight /> },
          {
            TEXT: '1/  Side stretch',
          },
          {
            TEXT:
              'Targeting both internal and external obliques. Remember they are located where your pockets are.',
          },
          {
            TEXT: <Image src="/images/bp6-1.jpg" />,
          },
          { TEXT: <Highlight /> },
          {
            TEXT: '2/ Bike crunch',
          },
          {
            TEXT:
              'I love this one, twist as far as you can either side to really work your obliques.',
          },
          {
            TEXT: <Image src="/images/bp6-2.jpg" />,
          },
          { TEXT: <Highlight /> },
          {
            TEXT: '3/ Russian Twist',
          },
          {
            TEXT:
              'One of my favourite in class too. working all 4 abs muscles.',
          },
          {
            TEXT: <Image src="/images/bp6-3.jpg" />,
          },
          { TEXT: <Highlight /> },
          {
            TEXT: '4/ Side Crunch',
          },
          {
            TEXT: 'Thats a killer but for all the right reasons.',
          },
          {
            TEXT: <Image src="/images/bp6-4.jpg" />,
          },
          { TEXT: <Highlight /> },
          {
            TEXT: '5/ Plank Hip Dips',
          },
          {
            TEXT: 'Working your shoulders and your abs, this is a great one.',
          },
          {
            TEXT: (
              <div>
                <Image src="/images/bp6-5.jpg" />
                <Image src="/images/bp6-6.jpg" />
              </div>
            ),
          },
          {
            TEXT: 'Nathalie xo',
          },
        ],
      },
      {
        ID: 4,
        TITLE: '3 main benefits of barre',
        READING_TIME: '5 mins',
        DATE: '28/05/2019',
        IMAGE: '/images/NBP-FR-100-min.jpg',
        PARAS: [
          {
            TEXT:
              'Realistically, Barre is known to help strengthen your quads, ankles, hamstrings, gluteal muscles (gluteus maximus) and your core (abdominals and pelvic floor) ',
          },
          {
            TEXT:
              'Taking up Ballet Barre is definitely the answer to a more chiselled and toned body, and a more energetic and injury-free lifestyle, but there are many other benefits you can get from barre. Here are my top 3:',
          },
          { TEXT: <Highlight /> },
          {
            TEXT:
              '1/ You might find it surprising, but at the top of my list is positive mindset and energy. Barre is a high intensity and low impact fitness exercise and like most fitness classes, it will give you an incredible boost of energy and will switch your mindset and possibly turn you into a more go-getter person. Let me explain. Doing barre weekly is a challenge in itself, learning new moves, breathing through the shakes and the burn, this is all very intense. My experience has shown that people excel when they are challenged and a class that lets you win all the time, is not the class you should sign up to. Most people need to attend a class that is letting them have “a win” only 60-70% of the class, the rest of the class should either be too hard or too fast or make you burn too much that you have to reset in the middle of the routine. I have noticed that people like that, they like when it’s hard. That difficulty creates a different mindset, it gets you used to not always winning, but always persevering and working harder to get closer to what you wish to achieve. This is what barre is, a never ending challenge. wait… and so is life, right?',
          },
          { TEXT: <Highlight /> },
          {
            TEXT:
              '2/ Barre will chisel, strengthen, lengthen and tone up with your entire body. Don’t believe anyone who says that barre is only for girls and that barre is basically just some graceful ballet moves. Barre is tough and all my barre students are so balanced and have such strong legs and core, it’s impressive. Every time I have a ‘ barre virgin’ in the class, the strength that my regular customers have under their belts is a testament to barre and to their perseverance. At that point, I really see the results of the work that we do together and they can also see how far they have come. At the barre, you will work your feet, ankles, calves, quads, hamstring, glutes, entire core, chest and arms in a very specific way  that will help tone up each muscle group and elongate your entire figure.',
          },
          { TEXT: <Highlight /> },
          {
            TEXT:
              '3/ My final top 3 benefit of barre has to be the posture and flexibility you will acquire during classes. It will be hard at first, but after 1 month or two, you will notice a real change in your posture. You will find yourself correcting your spine and pelvis position in your every day life. Most of my clients started with me, not being too sure what being straight meant. They thought their spine was in a nice straight position when actually they were slightly tilted. This is what barre is, it’s a mix of a lot of information, knowledge and experience being passed from the instructor to the student.',
          },
          { TEXT: <Highlight /> },
          {
            TEXT:
              'I hope that these main benefits will convince you to come and try barre soon.',
          },
          {
            TEXT: 'Nathalie xo',
          },
        ],
      },
      {
        ID: 3,
        TITLE: '5 tips to prepare for your first barre class?',
        READING_TIME: '5 mins',
        DATE: '20/05/2019',
        IMAGE: '/images/NBP-FR-58-min.jpg',
        PARAS: [
          {
            TEXT:
              'This Spring lots of women will have taken up a new fitness exercise, and trends show that many people turn to Barre.',
          },
          {
            TEXT:
              'Barre is one the most efficient fitness exercises around because it challenges a variety of muscle groups and it’s low impact. However, going to a new fitness class for the first time can be intimidating.',
          },
          {
            TEXT:
              'As a barre instructor myself, I work with beginners to make them feel welcome, comfortable and confident in my classes.',
          },
          {
            TEXT:
              'Here are my 5 tips to arrive to your first barre class ready to kill it:',
          },
          { TEXT: <Highlight /> },
          {
            TEXT:
              '1/ Introduce yourself to your instructor so they know how best to support you. Actually, the instructor should really have introduced themselves to you when you arrive but if they haven’t, speak to them, and let them know it’s your first time so they can adapt the class accordingly.',
          },
          { TEXT: <Highlight /> },
          {
            TEXT:
              '2/ Let the instructor know if you have any injuries. Again, the instructor should have asked you first, but if they haven’t, let them know so you will be given modifications throughout the class to follow the routines.',
          },
          { TEXT: <Highlight /> },
          {
            TEXT:
              '3/ Get a grasp on the language used, most barre instructors will use some ballet terms to describe the positions: parallel, first, second and fourth are the most common positions used at the barre. You will also hear the terms: plies, tendus, passes ... don’t be afraid to ask your instructor if you don’t understand, you are not a dancer and no one expects you to know the terms on your first barre class.',
          },
          { TEXT: <Highlight /> },
          {
            TEXT:
              '4/ What to wear? You should be wearing some leggings and a gym tee or whatever feels more comfortable for you to move in. Keep in mind that you will be sweating, so short sleeves tee is highly recommended for your comfort. You should also wear some grip socks, however if it’s your first class and you are not sure you will carry on, don’t invest just yet and just wear a thin pair of ankle socks. Don’t forget a bottle of water too, you will need it.',
          },
          { TEXT: <Highlight /> },
          {
            TEXT:
              '5/ What to expect? A barre class should be composed of a quick warm up in the centre or sometimes you can start at the barre straight away but you will always start with some plies et releves to warm up your legs. You will then spend about 30mn at the barre, then come on a mat for some Pilates movements concentrating more on arms, abs, core and possibly more glutes work. You will then stretch for the last 8mn of the class. Usually in my classes, I stop the banging tunes and change the atmosphere of the room with some yoga relaxing music and switch off the lights to allow my clients to stretch, relax, close their eyes and tune in with their breathing.',
          },
          { TEXT: <Highlight /> },
          {
            TEXT:
              'I hope that my 5 tips will help you to be barre ready in case you decide to attend your first barre class soon.',
          },
          {
            TEXT: 'Nathalie xo',
          },
        ],
      },
      {
        ID: 2,
        TITLE:
          'How does your hormonal menstrual cycle impact your fitness routine?',
        READING_TIME: '7 mins',
        DATE: '11/05/2019',
        IMAGE: '/images/NBP-FR-2-min.jpg',
        PARAS: [
          {
            TEXT:
              'Well, well, well…this subject is a big one for me as I suffer greatly myself from PMS and period symptoms. My world usually comes crashing down once a month, my mood is terrible and the pain is horrendous. There are usually 1-2 days when I make sure not to have any classes when I am at my worse…but is avoiding workouts at that time actually good for me?',
          },
          {
            TEXT:
              'I have wanted to discuss this topic for a while but I never knew if it was something that women might be interested in as I thought I was one of the very few to go through this so intensely. I discovered lately by simply opening up about my symptoms to my friends and some close clients that a lot of women are encountering the same issues and don’t really know what to do when it comes to fitness. I understand and I can relate greatly to women who are asking themselves the very important question:',
          },
          {
            TEXT: 'Are workouts recommended during both PMS and period time?',
          },
          {
            TEXT: 'The answer is YES and YES.',
          },
          { TEXT: <Highlight /> },
          {
            TEXT: 'During PMS:',
          },
          {
            TEXT:
              'These PMS can be so bad for some women that they can affect your normal life. They can affect your mood, your sleep or even your concentration. You can feel more tired, bloated, or hungry and have weird cravings.',
          },
          {
            TEXT:
              'There are many ways to deal with these symptoms and fitness happens to be one of the best ones. Let me explain why.',
          },
          {
            TEXT:
              'Exercise is known to boost important brain chemicals called endorphins. Increased endorphins help reduce the amount of pain you feel from PMS. Endorphins also help with improving your mood swings and sometimes depression, this is why the endorphins are called the feel-good  hormones. Swimming, fast walking or aerobic classes such as barre, dance or dynamic Pilates are usually recommended. Yoga is good to release the stress endured during PMS too.',
          },
          { TEXT: <Highlight /> },
          {
            TEXT: 'During the period:',
          },
          {
            TEXT:
              'Tell me if the following symptoms sound familiar. Headaches, moodiness,  bloat and ( severe) pains.??',
          },
          {
            TEXT:
              'Well , all of these period symptoms can subdue when you exercise. This is true.',
          },
          {
            TEXT:
              'Exercising might actually be the best thing to do when you are on your period, it will help depress belly cramps or feel better about bloat. Moving and sweating increase oxygen-supply to the muscles. The added boost of endorphins that comes from exercise can serve to boost your metabolism during a period when you don’t usually feel very good about yourself.',
          },
          {
            TEXT:
              'Instead of treating menstrual migraines with medication, it’s much healthier to head out to a cardio barre class I say. Intense exercise causes the body to sweat, which helps reduce any period belly bloat.',
          },
          { TEXT: <Highlight /> },
          {
            TEXT:
              'So, you know what to do now. Be sure to attend your fitness classes (as normal) and you will definitely notice the difference! Drink lots of water, go buy yourself the SUPER PLUS protection... and head to the studio. 👏',
          },
        ],
      },
      {
        ID: 1,
        TITLE: '3 main misconceptions about ballet barre',
        READING_TIME: '5 mins',
        DATE: '04/05/2019',
        IMAGE: '/images/NBP-FR-193-min.jpg',
        PARAS: [
          { TEXT: <Highlight /> },
          {
            TEXT: 'You don’t need to be a ballerina to attend a barre class.',
          },
          {
            TEXT:
              'You don’t need to be graceful or be flexible either. I would say that having an instructor with a ballet background is really good as that person can teach you a lot about the right posture and will be familiar with the terminology and how to execute each movement in the best possible way. However, as a student you don’t need to know classical dancing to do barre. You will be asked to point your toes, straighten your legs and move your arms up and down. You will also learn the basic positions of classical ballet, but that is as far as it goes.',
          },
          { TEXT: <Highlight /> },
          {
            TEXT: 'Ballet barre will not make you lose weight. ',
          },
          {
            TEXT:
              'It helps you tone up and strengthen various muscles groups, and if it’s done together with some cardio and a healthy diet, you will eventually burn fat and lose weight. Losing weight is down to a healthy and varied diet, exercise will help redefine your muscles and if you want long, lean and strong muscles, then barre is a perfect workout.',
          },
          { TEXT: <Highlight /> },
          {
            TEXT: 'Barre is too hard and will never get easier.',
          },
          {
            TEXT:
              'Like I say to my clients, perseverance is key. Yes, barre is not easy. And the reason why barre challenges your body is because you will feel what we call the BURN, throughout the whole class. All the plies, the pulses and the extensions challenge your muscle tissues in a very specific way. These movements are called isometric contractions and work your muscles to exhaustion by doing a lot of micro-movements and a lot of repetitions of these movements. So the next time you are cursing at your barre instructor, while they tell you to embrace the shake and the burn, remember, it is normal and well...they have a point!',
          },
        ],
      },
    ],
  },
};
