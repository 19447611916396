import React from 'react';
import { oneOf, node, string } from 'prop-types';
import cx from 'classnames';
import styles from './ContentRowsLayout.module.scss';

const ContentRowsLayout = ({ className, children, size }) => (
  <div className={cx(styles.layout, styles[size], className)}>{children}</div>
);

ContentRowsLayout.defaultProps = {
  className: null,
  size: 'component',
};

ContentRowsLayout.propTypes = {
  className: string,
  children: node.isRequired,
  size: oneOf(['atom', 'element', 'component', 'module', 'feature']),
};

export default ContentRowsLayout;
