import React, { Component } from 'react';
import cx from 'classnames';
import { arrayOf, bool, func, node, string, shape } from 'prop-types';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import namespace from '../../../tools/Namespace';

/**
 * Check if event if mouse left click.
 *
 * @param {Object} event
 * @returns {bool}
 */
function isLeftClickEvent(event) {
  return event.button === 0;
}

/**
 * Check if event is using a modifier key.
 *
 * @param {Object} event
 * @returns {bool}
 */
function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class Link extends Component {
  static propTypes = {
    href: string.isRequired,
    children: node.isRequired,
    inverted: bool,
    onClick: func,
    history: shape({}),
    className: string,
    defaultClass: bool,
    variants: arrayOf(string),
  };

  static defaultProps = {
    inverted: null,
    onClick: () => {},
    history: null,
    className: null,
    defaultClass: true,
    variants: null,
  };

  /**
   * Handle onClick.
   *
   * @param {Object} event
   */
  handleClick = event => {
    const { onClick, history, href } = this.props;
    onClick(event);

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    if (history) {
      event.preventDefault();
      history.push(href);
    }
  };

  render() {
    const {
      href,
      defaultClass,
      children,
      className,
      inverted,
      variants,
      ...rest
    } = this.props;
    const modifiers = variants ? variants.map(el => `link--${el}`) : null;
    const classes = cx(className, namespace(modifiers), {
      [namespace('link')]: defaultClass,
      [namespace('link--inverted')]: inverted,
    });
    const filteredProps = filterInvalidDOMProps(rest);

    return (
      <a
        className={classes}
        href={href}
        {...filteredProps}
        onClick={this.handleClick}
      >
        {children}
      </a>
    );
  }
}

export default Link;
