import React from 'react';
import { node, string, oneOf } from 'prop-types';
import cx from 'classnames';
import namespace from '../../../tools/Namespace';

const Card = ({ align, children, className, size, type, ...props }) => {
  const classes = cx(namespace(['card', `card--${type}`]), className, {
    [namespace(`card--${size}`)]: size,
    [`text-${align}`]: align,
  });

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

Card.defaultProps = {
  align: null,
  className: null,
  size: null,
  type: 'default',
};

Card.propTypes = {
  align: oneOf(['left', 'center', 'right', 'justify']),
  /**
   * Child nodes to render inline.
   */
  children: node.isRequired,
  /**
   * Useful to extend the style applied to components.
   */
  className: string,
  /**
   * Layout sizing options.
   */
  size: oneOf(['none', 'small', 'large']),
  /**
   * Available card types for outputting class modifiers.
   */
  type: oneOf(['default', 'basic', 'framed']),
};

export default Card;
