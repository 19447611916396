import React from 'react';
import uuidv4 from 'uuidv4';
import { string } from 'prop-types';
import cx from 'classnames';
import Link from '../../UI/RouterLink';
import strings from '../../../translations';
import PATHS from '../../../routes';
import styles from './FooterMenu.module.scss';

const {
  MAIN: { PRIVACY_POLICY, COOKIE_POLICY },
} = strings.GLOBAL.NAVIGATION;

const FooterMenu = ({ className }) => {
  const menuLinks = [
    {
      link: PATHS.privacyPolicy,
      name: PRIVACY_POLICY,
    },
    {
      link: PATHS.cookiePolicy,
      name: COOKIE_POLICY,
    },
  ];

  return (
    <nav id="navigation" className={className}>
      {menuLinks &&
        menuLinks.map(data => (
          <div className={styles.navitem} key={uuidv4()}>
            <Link href={data.link} className={cx('nrg-link')}>
              {data.name}
            </Link>
          </div>
        ))}
      <div className={styles.navitem}>
        <a
          className="nrg-link nrg-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.n-rg.co.uk/schedule"
        >
          NRG Schedule
        </a>
      </div>
    </nav>
  );
};

FooterMenu.propTypes = {
  className: string,
};

FooterMenu.defaultProps = {
  className: null,
};

export default FooterMenu;
