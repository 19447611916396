import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { node, func, oneOf } from 'prop-types';
import Link from '../../Atoms/Link';
import Button from '../../Molecules/Button';

// A simple component that shows the pathname of the current location
class RouterLink extends Component {
  static propTypes = {
    children: node,
    onClick: func,
    type: oneOf(['Link', 'Button']),
  };

  static defaultProps = {
    children: null,
    onClick: null,
    type: 'Link',
  };

  constructor() {
    super();

    this.changeHandler = this.changeHandler.bind(this);
  }

  changeHandler() {
    const { onClick } = this.props;
    if (typeof onClick === 'function') {
      onClick(this.props);
    }
  }

  render() {
    const { children, type, ...props } = this.props;
    let elementRender;
    if (type === 'Link') {
      elementRender = (
        <Link {...props} onClick={this.changeHandler}>
          {children}
        </Link>
      );
    }
    if (type === 'Button') {
      elementRender = (
        <Button {...props} onClick={this.changeHandler}>
          {children}
        </Button>
      );
    }
    return elementRender;
  }
}

export default withRouter(RouterLink);
