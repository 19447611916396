import React, { Fragment } from 'react';
import cx from 'classnames';
import DocumentTitle from 'react-document-title';
import { Router, Route, Switch } from 'react-router-dom';
import history from './tools/createBrowserHistory';
import ScrollToTop from './components/UI/ScrollToTop';
import Header from './components/Elements/Header';
import Footer from './components/Elements/Footer';
import PATHS from './routes';
import strings from './translations';

/** PAGES */
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Article from './components/Pages/Article';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import CookiePolicy from './components/Pages/CookiePolicy';
import NotFound from './components/Pages/NotFound';
import './App.scss';

const { SITENAME } = strings.GLOBAL;

/**
 * App render.
 */
function App() {
  return (
    <Fragment>
      <DocumentTitle title={SITENAME}>
        <Router history={history}>
          <ScrollToTop>
            <div className={cx('App', 'root')}>
              <Header />
              <div className="root-content">
                <Switch>
                  <Route exact path={PATHS.home} component={Home} />
                  <Route exact path={PATHS.about} component={About} />
                  <Route exact path={PATHS.article} component={Article} />
                  <Route
                    exact
                    path={PATHS.cookiePolicy}
                    component={CookiePolicy}
                  />
                  <Route
                    exact
                    path={PATHS.privacyPolicy}
                    component={PrivacyPolicy}
                  />
                  <Route component={NotFound} />
                </Switch>
              </div>
              <Footer />
            </div>
          </ScrollToTop>
        </Router>
      </DocumentTitle>
    </Fragment>
  );
}

export default App;
