import React from 'react';
import { array, arrayOf, bool, element, oneOfType, string } from 'prop-types';
import cx from 'classnames';
import namespace from '../../../tools/Namespace';

const Heading = ({ inverted, children, tag: Tag, className, ...props }) => {
  const classes = cx(namespace('heading'), className, {
    [namespace('inverted')]: inverted,
  });

  return (
    <Tag className={classes} {...props}>
      {children}
    </Tag>
  );
};

Heading.propTypes = {
  children: oneOfType([string, arrayOf(element), element, array]).isRequired,
  tag: string,
  className: string,
  inverted: bool,
};

Heading.defaultProps = {
  tag: 'h2',
  className: 'h2',
  inverted: false,
};

export default Heading;
