import React, { Fragment } from 'react';
import { shape } from 'prop-types';
import uuidv4 from 'uuidv4';
import DocumentTitle from 'react-document-title';
import Heading from '../../Atoms/Heading';
import ContentPageLayout from '../../Elements/ContentPageLayout';
import ContentRowsLayout from '../../Elements/ContentRowsLayout';
import BannerImage from '../../UI/BannerImage';
import styles from './Article.module.scss';
import strings from '../../../translations';
import Type from '../../Atoms/Type';

const { SITENAME } = strings.GLOBAL;
const {
  BLOG: { POSTS },
} = strings;

const Article = props => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const POST_DATA = POSTS.filter(post => post.ID === Number(id));
  const { TITLE, DATE, IMAGE, PARAS, READING_TIME } = POST_DATA[0];
  const COPY = PARAS.map(p => {
    return <p key={uuidv4()}>{p.TEXT}</p>;
  });

  return (
    <DocumentTitle title={`${TITLE} | ${SITENAME}`}>
      <Fragment>
        <BannerImage image={IMAGE} isBanner narrow />
        <ContentPageLayout>
          <ContentRowsLayout size="atom">
            <Heading tag="h1">{TITLE}</Heading>
            <Type as="p">Post date : {DATE}</Type>
            <Type as="p">Reading time : {READING_TIME}</Type>
            <div className={styles.layout}>{COPY}</div>
          </ContentRowsLayout>
        </ContentPageLayout>
      </Fragment>
    </DocumentTitle>
  );
};

Article.propTypes = {
  match: shape({}).isRequired,
};

export default Article;
