export default {
  home: '/',
  contact: '/contact',
  article: '/article/:id',
  schedule: '/schedule',
  help: '/help',
  faqs: '/faqs',
  whatWeDo: '/what-we-do',
  about: '/about',
  nutrition: '/nutrition',
  charity: '/charity',
  health: '/health',
  press: '/press',
  shop: 'https://shop.n-rg.co.uk/',
  locations: '/locations',
  testimonials: '/testimonials',
  terms: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  cookiePolicy: '/cookie-policy',
  studioPolicy: '/studio-policy',
  cookiesPolicy: '/cookies-policy',
  INSTAGRAM_ACCOUNT: 'https://www.instagram.com/nrg.barrebody/',
  YOUTUBE_ACCOUNT: 'https://www.youtube.com/channel/UC_7FpOBcOS_x-N32JswUW0Q',
  ONLINE_SHOP: 'https://shop.n-rg.co.uk/',
};
