import React, { Fragment } from 'react';
import uuidv4 from 'uuidv4';
import DocumentTitle from 'react-document-title';
import Heading from '../../Atoms/Heading';
import ContentPageLayout from '../../Elements/ContentPageLayout';
import ContentRowsLayout from '../../Elements/ContentRowsLayout';
import BannerImage from '../../UI/BannerImage';
import strings from '../../../translations';

const { SITENAME } = strings.GLOBAL;
const { TITLE, INFO, INTRO } = strings.PAGES.ABOUT;

const LIST = INFO.map(data => {
  return (
    <div key={uuidv4()}>
      <p>{data.TEXT}</p>
    </div>
  );
});

const About = () => (
  <DocumentTitle title={`${TITLE} | ${SITENAME}`}>
    <Fragment>
      <BannerImage image="/images/NBP-FR-13-min.jpg" isBanner />
      <ContentPageLayout>
        <ContentRowsLayout>
          <Heading tag="h1" className="sr-only">
            {TITLE}
          </Heading>
          <Heading tag="h2" className="h4">
            {INTRO}
          </Heading>
          <div>{LIST}</div>
        </ContentRowsLayout>
      </ContentPageLayout>
    </Fragment>
  </DocumentTitle>
);

export default About;
