import React from 'react';
import { node, string, arrayOf, shape } from 'prop-types';
import cx from 'classnames';
import ResponsiveImage from '../../Atoms/ResponsiveImage';
import Image from '../../Atoms/Image';
import namespace from '../../../tools/Namespace';

const CardMedia = ({
  children,
  className,
  src,
  srcSets,
  alt,
  title,
  ...props
}) => {
  if (srcSets) {
    return (
      <div className={cx(namespace('card__media'), className)}>
        <ResponsiveImage
          {...props}
          srcSets={srcSets}
          fallback={src}
          alt={alt}
          title={title}
        />
      </div>
    );
  }

  return (
    <div className={cx(namespace('card__media'), className)}>
      <Image {...props} src={src} alt={alt} title={title} />
    </div>
  );
};

CardMedia.defaultProps = {
  children: null,
  className: null,
  srcSets: null,
  title: '',
};

CardMedia.propTypes = {
  /**
   * Child nodes to render inline.
   */
  children: node,
  /**
   * Useful to extend the style applied to components.
   */
  className: string,
  /**
   * Image src URL.
   */
  src: string.isRequired,
  /**
   * Responsive image source set.
   */
  srcSets: arrayOf(
    shape({
      size: string.isRequired,
      src: string.isRequired,
      type: string,
    }),
  ),
  /**
   * Image alt text.
   */
  alt: string.isRequired,
  /**
   * Image title text.
   */
  title: string,
};

export default CardMedia;
