import React from 'react';
import {
  func,
  node,
  oneOf,
  oneOfType,
  number,
  string,
  arrayOf,
} from 'prop-types';
import cx from 'classnames';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import namespace from '../../../tools/Namespace';
import Link from '../../Atoms/Link';

/**
 * Custom prop type validator to ensure `link` tag's always provide a `href` prop.
 *
 * @param {Object} props
 * @param {string} propName
 * @returns {Error|null}
 */
function hrefChecker(props, propName) {
  const { [propName]: value, tag } = props;
  if (tag === 'link' && typeof value !== 'string' && !value) {
    return new Error(`${propName} must be provided`);
  }
  return null;
}

const Button = ({
  onClick,
  href,
  tag,
  variants,
  children,
  className,
  key,
  ...props
}) => {
  const modifiers = variants.map(el => `btn--${el}`);
  const classes = cx(namespace(['btn']), namespace(modifiers), className);
  const filteredProps = filterInvalidDOMProps(props);

  if (tag === 'link') {
    return (
      <Link onClick={onClick} href={href} className={classes} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <button
      onClick={onClick}
      className={classes}
      key={key}
      type="button"
      {...filteredProps}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  variants: arrayOf(string),
  tag: oneOf(['link', 'button']),
  type: string,
  href: hrefChecker,
  className: string,
  children: node.isRequired,
  onClick: func,
  value: oneOfType([number, string]),
  key: string,
};

Button.defaultProps = {
  className: null,
  variants: ['default'],
  href: null,
  tag: 'button',
  type: 'button',
  onClick: null,
  value: null,
  key: null,
};

export default Button;
