import React from 'react';
import { bool, string, oneOfType, oneOf, array } from 'prop-types';
import cx from 'classnames';
import { isArray } from 'util';
import uuidv4 from 'uuidv4';
import filterInvalidDOMProps from 'filter-invalid-dom-props';
import namespace from '../../../tools/Namespace';

const Image = props => {
  const { alt, className, caption, figure, fluid } = props;
  const figureClasses = cx(namespace('figure'));
  const classes = cx(namespace('image'), className, {
    [namespace('image--fluid')]: fluid,
  });
  const filteredProps = filterInvalidDOMProps(props);
  const renderImg = <img alt={alt} className={classes} {...filteredProps} />;
  const renderCaption = isArray(caption)
    ? caption.map(item => <span key={uuidv4()}>{item}</span>)
    : caption;

  /**
   * Render as a figure.
   */
  if (figure || caption) {
    return (
      <figure className={figureClasses}>
        {renderImg}
        {caption && <figcaption>{renderCaption}</figcaption>}
      </figure>
    );
  }

  /**
   * Default render as an image.
   */
  return renderImg;
};

Image.defaultProps = {
  alt: '',
  className: null,
  caption: null,
  figure: false,
  fluid: false,
  crossOrigin: null,
};

Image.propTypes = {
  src: string.isRequired,
  alt: string,
  className: string,
  /** Image caption, will display as a `figure` if set. */
  caption: oneOfType([string, array]),
  /** Display within a `figure`. */
  figure: bool,
  /** The image will take up the entire width of the container. */
  fluid: bool,
  crossOrigin: oneOf(['anonymous', 'use-credentials']),
};

export default Image;
